<template>
    <BaseSection
        v-if="entries && entries.length"
        section-width="content"
        class="entries-list"
        :read-more="{
            uri: readMoreUri,
            text: 'Bekijk alles'
        }"
    >
        <template
            v-if="title"
            #title
        >
            {{ title }}
        </template>

        <template #default>
            <CardList>
                <template v-for="entry in entries">
                    <ArticleCard
                        v-if="entry.sectionHandle === 'articles'"
                        :key="entry.id"
                        :entry="entry"
                        :is-inline="true"
                    />

                    <AgendaCard
                        v-if="entry.sectionHandle === 'agenda' && [entry.typeHandle === 'deelopleiding' || entry.typeHandle === 'bijeenkomst']"
                        :key="entry.id"
                        :entry="entry"
                        :is-inline="true"
                    />
                </template>
            </CardList>

            <div
                v-if="$slots.after"
                class="entries-list__after"
            >
                <slot name="after" />
            </div>
        </template>
    </BaseSection>
</template>

<script setup>
defineProps({
    entries: {
        type: Array,
        default: null
    },

    title: {
        type: String,
        default: null
    },

    readMoreUri: {
        type: String,
        default: null
    }
});
</script>

<style lang="less">
.entries-list__after {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
}
</style>
